import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../components/layout/Layout'
import Bio from '../components/bio/Bio'
import { InfoCallout, TweetCallout } from '../components/callout/Callout'
import Tag from '../components/tags/Tag'
import ComicCoverImage from '../components/comic-cover-image/comic-cover-image'
import Newsletter from '../components/newsletter/Newsletter'
import * as styles from './BlogPost.module.css'
import { getFormattedDate, toReadingTime } from '../utils/formatting'

import { rhythm } from '../utils/typography'
import { getImage } from 'gatsby-plugin-image'

class MdxPageTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { mdx } = data
    const siteTitle = data.site.siteMetadata.title

    const { date,
      dateModified,
      categories = [],
      description,
      title,
      keywords,
      image,        // social media image
      cover_image,  // cover image
      cover_image_alt: coverImageAlt,
      cover_image_title: coverImageTitle,
      // TODO: Add video
      published_draft_state: publishedDraftState,
    } = mdx.frontmatter
    const { timeToRead } = mdx
    const formattedDate = getFormattedDate(date)
    const readingTime = toReadingTime(timeToRead)
    const [mainCategory] = categories || []
    const isPublishedDraft = !!publishedDraftState

    // TODO: Extract to common component or hook?
    // prep image for social sharing
    // this social media image comes from the frontmatter social_image field
    // that is transformed into an image when querying GraphQL with childImageSharp
    // TODO: we should be able to get the size of the image here so we can appropriately
    // set the size in the generated HTML to improve CLS (content layout shift) and SEO
    // TODO: Read gatsby docs, I bet they have a solution for this already and
    // I'm just f***ing around with workarounds
    const socialImage = image
      ? image.childImageSharp.resize
      : null
    if (socialImage) socialImage.alt = coverImageAlt

    // Extract gatsbyImageData from ImageSharp node so that we can use it with the GatsbyImage
    // components that lets us use optimized images.
    const coverImage = getImage(cover_image)

    return (
      <Layout>
        <article>
          <article className={styles.article}>
            <SEO title={`${title} | ${siteTitle}`} description={description}
              keywords={keywords}
              image={socialImage}
              date={date}
              dateModified={dateModified}
            />
            <header className={styles.header}>
              <time className={styles.date} style={{ display: 'none' }}>
                {formattedDate}
              </time>
              <span className={styles.readingtime}>{readingTime}</span>
              {mainCategory && (
                <Tag style={{ float: 'right' }} small>
                  {mainCategory}
                </Tag>
              )}
              {
                // TODO(vintharas): make this not behave like a category
                // right now since we reuse categories here we need to
                // mark all drafts as belonging to the category 'draft' :D
                // to not break how the tag component works. May be useful to
                // use a different type of tag
              }
              {isPublishedDraft && (
                <Tag style={{ float: 'right' }} small alert>
                  {publishedDraftState}
                </Tag>
              )}
              <h1 style={{ paddingBottom: rhythm(1) }}>{title}</h1>
            </header>
            {coverImage && (
              <ComicCoverImage {...{ coverImage, coverImageAlt, coverImageTitle, description }} />
            )}
            <div className={styles.content}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </div>
            <hr style={{ marginBottom: rhythm(1) }} />
            <Bio />
            <hr style={{ marginBottom: rhythm(1) }} />
            <TweetCallout />
            {categories && (
              <InfoCallout
                style={{ marginBottom: rhythm(1) }}
                title="Tagged as..."
              >
                {categories.map((cat, idx) => (
                  <Tag key={idx}>{cat}</Tag>
                ))}
              </InfoCallout>
            )}
            <Newsletter
              theme="greenish"
              style={{
                boxShadow: '0px 1px 3px grey',
              }}
            />
          </article>
        </article>
      </Layout>
    )
  }
}

export default MdxPageTemplate

export const pageQuery = graphql`
  query MdxPageQuery($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      frontmatter {
        title
        date
        date_modified
        categories
        keywords
        description
        cover_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        cover_image_alt
        cover_image_title
        image: social_image {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        published_draft_state
      }
    }
  }
`
